<template>
  <ykc-form class="el-form-wrap">
    <div>
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
        <ykc-form-item label="省">
          <ykc-input disabled v-model="ruleForm.provinceName"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="市">
          <ykc-input disabled v-model="ruleForm.cityName"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="区" v-if="data.level == 3">
          <ykc-input disabled v-model="ruleForm.areaName"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="所属大区" prop="region" v-if="data.level == 2">
          <ykc-dropdown :data="regionData" v-model="ruleForm.region"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="投建系数" prop="coefficient" v-if="data.level == 2">
          <ykc-input v-model="ruleForm.coefficient"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="区域性质" prop="nature" v-if="data.level == 3">
          <ykc-dropdown :data="natureData" v-model="ruleForm.nature"></ykc-dropdown>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import { areaManageApi } from '@/service/apis';

  export default {
    props: {
      data: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        regionData: [
          { id: 1, name: '总部' },
          { id: 2, name: '华南大区' },
          { id: 3, name: '华中大区' },
          { id: 4, name: '华北大区' },
          { id: 5, name: '西南大区' },
          { id: 6, name: '华东大区' },
        ],
        natureData: [
          { id: 1, name: '老城区' },
          { id: 2, name: '居住区' },
        ],
        ruleForm: {
          id: '',
          provinceName: '',
          cityName: '',
          areaName: '',
          region: '',
          coefficient: '',
          nature: '',
        },
        rules: {
          region: [{ required: false, message: '请选择所属大区', trigger: 'change' }],
          coefficient: [
            { required: false, message: '请输入投建系数', trigger: 'blur' },
            {
              validator: (_, val, cb) => {
                if (val === '') {
                  return cb();
                }
                const value = Number(val);
                console.log('value', value);
                if (value <= 0) {
                  cb(new Error('投建系数须大于0'));
                }
                if (Number.isNaN(value)) {
                  return cb(new Error('只能输入数字'));
                }
                if (!/^\d+(\.\d{1,2})?$/.test(val)) {
                  cb(new Error('最多两位小数'));
                }

                return cb();
              },
              trigger: 'blur',
            },
          ],
        },
      };
    },
    created() {
      // this.regionData = loadDicItem('region');
      // this.natureData = loadDicItem('nature');
      console.log(this.data);
      // 编辑回显
      if (this.data) {
        if (this.data.level === '2') {
          this.ruleForm = {
            id: this.data.id,
            provinceName: this.data.provinceName,
            cityName: this.data.name,
            coefficient: this.data.coefficient,
            region: this.data.region,
          };
        } else {
          this.ruleForm = {
            id: this.data.id,
            provinceName: this.data.provinceName,
            cityName: this.data.cityName,
            areaName: this.data.name,
            nature: this.data.nature,
          };
        }
      }
    },

    methods: {
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              let params = {};
              if (this.data.level === '2') {
                params = {
                  id: this.ruleForm.id,
                  coefficient: this.ruleForm.coefficient,
                  region: this.ruleForm.region,
                };
              } else {
                params = {
                  id: this.ruleForm.id,
                  nature: this.ruleForm.nature,
                };
              }
              areaManageApi
                .editDistrict(params)
                .then(res => {
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>

<!-- 系统设置 权限管理 账号管理 新增或编辑或数据权限 -->
<template>
  <ykc-drawer :show.sync="showDrawer" destroy-on-close :title="drawerTitle">
    <template #footer>
      <div class="drawer-button-wrap">
        <template v-for="(btn, i) in drawerButtons">
          <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
            {{ btn.label }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="drawer-main-body">
      <ykc-form>
        <el-steps :active="stepsIndex" align-center finish-status="success">
          <el-step title="账号配置" />
          <el-step title="权限配置" />
        </el-steps>
        <div style="padding-top: 20px">
          <ykc-form ref="YkcForm0" :model="model" :rules="rules" v-show="stepsIndex === 0">
            <ykc-form-item label="账号" prop="accountNo">
              <ykc-input
                v-model="model.accountNo"
                maxlength="30"
                placeholder="请输入账号"></ykc-input>
            </ykc-form-item>
            <ykc-form-item label="初始密码" prop="accountPwd" v-if="!this.currentAccount">
              <ykc-input
                v-model="model.accountPwd"
                minlength="6"
                maxlength="16"
                placeholder="请输入初始密码"></ykc-input>
            </ykc-form-item>
            <ykc-form-item label="姓名" prop="accountName">
              <ykc-input
                v-model="model.accountName"
                maxlength="50"
                placeholder="请输入姓名"></ykc-input>
            </ykc-form-item>
            <ykc-form-item label="手机号" prop="phone">
              <ykc-input
                v-model="model.phone"
                maxlength="11"
                placeholder="请输入手机号"></ykc-input>
            </ykc-form-item>
            <ykc-form-item
              prop="roleType"
              label="角色类型"
              bottomTip="温馨提示：平台管理员默认全平台数据可看"
              class="img-error">
              <ykc-radio
                :configSet="configSet"
                :data="roleTypeList"
                v-model="model.roleType"
                @change="changeType"></ykc-radio>
            </ykc-form-item>
            <ykc-form-item
              v-if="model.roleType != 4"
              prop="alarmFlag"
              label="接收告警短信"
              bottomTip="温馨提示：选择是为接收相关告警通知短信"
              class="img-error">
              <ykc-radio
                :configSet="configSet"
                :data="alarmFlagList"
                v-model="model.alarmFlag"></ykc-radio>
            </ykc-form-item>
            <ykc-form-item label="关联角色" prop="roleIdList">
              <ykc-tree
                :data="treeData"
                :props="treeProps"
                ref="YkcTree"
                @check-change="handleTreeCheckChange"></ykc-tree>
            </ykc-form-item>
          </ykc-form>
          <ykc-form ref="YkcForm1" :model="model" :rules="rules" v-show="stepsIndex === 1">
            <ykc-form-item v-if="model.roleType === '2'" label="商户管理员数据" prop="dataIdList">
              <ykc-tree
                nodeKey="operatorId"
                :data="treeData1"
                :props="treeProps1"
                ref="YkcTree1"
                @check-change="handleTreeCheckChange1"></ykc-tree>
            </ykc-form-item>
            <ykc-form-item v-if="model.roleType === '3'" label="电站管理员数据" prop="dataIdList">
              <ykc-tree
                nodeKey="stationId"
                :data="treeData1"
                :props="treeProps2"
                ref="YkcTree1"
                @check-change="handleTreeCheckChange1"></ykc-tree>
            </ykc-form-item>
            <ykc-form-item v-if="model.roleType === '4'" label="机构管理员数据" prop="dataIdList">
              <ykc-tree
                class="role-conf role-conf-sta"
                selectType="radio"
                :radioToogleable="false"
                :data="treeData1"
                :props="treeProps1"
                ref="YkcTree1"
                @radio-change="handleTreeRadioChange"></ykc-tree>
            </ykc-form-item>
          </ykc-form>
        </div>
      </ykc-form>
    </div>
  </ykc-drawer>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';

  import { localGetItem } from '@/utils';
  import regexpObj from '@/utils/regexp';
  import { roleManage, accountManage, operatorData, treeUtils, customerOrg } from '@/service/apis';

  const initModel = () => ({
    accountNo: '',
    accountPwd: '',
    accountName: '',
    phone: '',
    alarmFlag: '0',
    roleType: '1',
    roleIdList: [],
    dataIdList: [],
  });

  export default {
    props: ['currentAccount', 'showDrawer'],
    watch: {
      showDrawer(val) {
        if (val) {
          this.handleOpen();
        }
      },
      // eslint-disable-next-line
      'model.roleType'(val) {
        if (val !== '4') {
          this.rules.alarmFlag[0].required = true;
        } else {
          this.rules.alarmFlag[0].required = false;
          this.model.alarmFlag = '';
        }
        this.$refs.YkcForm0?.validateField('alarmFlag');
      },
    },
    data() {
      return {
        configSet: {
          label: 'name',
          value: 'code',
        },
        maxIndex: 1,
        stepsIndex: 0,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.stepsIndex = 0;
                  this.model = initModel();
                  this.$emit('closeDrawer');
                },
              });
            },
          },
          {
            label: '上一步',
            enabled: () => {
              return this.stepsIndex !== 0;
            },
            handler: () => {
              this.$refs.YkcForm1.resetFields();
              this.stepsIndex--;
            },
          },
          {
            label: '下一步',
            enabled: () => {
              return this.model.roleType !== '1' && this.stepsIndex !== this.maxIndex;
            },
            handler: () => {
              this.$refs.YkcForm0.validate(valid => {
                if (valid) {
                  this.stepsIndex++;
                  if (this.model.roleType === this.currentAccount?.roleType) {
                    this.getMenuTree1(true);
                  } else {
                    this.getMenuTree1();
                  }
                }
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return this.model.roleType === '1' || this.stepsIndex === this.maxIndex;
            },
            handler: () => {
              if (this.stepsIndex !== this.maxIndex) {
                this.$refs.YkcForm0.validate(valid => {
                  if (valid) {
                    this.beforeEnsure();
                  }
                });
              } else {
                this.$refs.YkcForm1.validate(valid => {
                  if (valid) {
                    this.beforeEnsure();
                  }
                });
              }
            },
          },
        ],
        drawerTitle: '角色新增',
        roleTypeList: localGetItem('dictionary')?.role_type || [],
        alarmFlagList: [
          {
            code: '1',
            name: '是',
          },
          {
            code: '0',
            name: '否',
          },
        ],
        model: initModel(),
        rules: {
          roleType: [{ required: true, message: '请输入选择角色类型', trigger: 'blur' }],
          alarmFlag: [
            {
              required: true,
              message: '请选择是否接收告警短信',
              trigger: 'blur',
            },
          ],
          accountNo: [
            { required: true, message: '请输入账号', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.accountType.errorTips.error('账号'),
            },
          ],
          accountPwd: [
            { required: true, message: '请输入初始密码', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.passwordType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.passwordType.errorTips.error,
            },
          ],
          accountName: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.nameType.errorTips.error('姓名'),
            },
          ],
          phone: [
            { required: false, message: '请输入手机号码', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.cellphoneType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.cellphoneType.errorTips.error('手机号'),
            },
          ],
          roleIdList: [
            { required: true, type: 'array', message: '请选择关联角色', trigger: 'blur' },
          ],
          dataIdList: [
            { required: true, type: 'array', message: '请选择关联数据', trigger: 'blur' },
          ],
        },
        treeData: [],
        treeData1: [],
        treeProps: {
          label: 'roleName',
          children: 'subNodes',
          disabled: 'disabled',
        },
        treeProps1: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        treeProps2: {
          label: 'stationName',
          children: 'stationTreeVOList',
          disabled: 'disabled',
        },
        selectOperaIdList: [], // 第二步 回显
        selectRoleListId: [], // 第一步 回显
      };
    },
    methods: {
      // 角色类型切换
      changeType(roleType) {
        this.getMenuTree(roleType);
      },
      getDetail() {
        return accountManage.detail(this.currentAccount.id).then(res => {
          this.selectRoleListId = res?.selectRoleListId || [];
          this.selectOperaIdList = res?.selectOperaIdList || [];
          this.model.phone = res?.phone || '';
          this.model.alarmFlag = res?.alarmFlag || '0';
        });
      },
      // 抽屉展示
      async handleOpen() {
        this.model = initModel();
        if (this.currentAccount) {
          this.drawerTitle = '账号编辑';
          Object.keys(this.model).forEach(key => {
            this.model[key] = this.currentAccount[key];
          });
          await this.getDetail();
          if (this.currentAccount.activeStep === 1) {
            this.stepsIndex = 1;
            this.getMenuTree1(true);
          }
        } else {
          this.drawerTitle = '账号新增';
        }
        this.getMenuTree(this.currentAccount?.roleType || '1');
      },
      // 菜单树初始化选中状态
      handleTreeMounted() {
        if (this.currentAccount) {
          const keys = this.selectRoleListId;
          console.log('handleTreeMounted', keys);
          this.model.roleIdList = [...keys];
          this.$refs.YkcTree.setCheckedKeys(keys, true);
        }
      },
      // 菜单树变化
      handleTreeCheckChange() {
        const keys = this.$refs.YkcTree.getCheckedKeys(true);
        console.log('keys', keys);
        this.model.roleIdList = keys;
      },
      // 菜单树初始化
      async getMenuTree(roleType = '1') {
        this.treeData = [];
        this.model.roleIdList = [];
        roleManage.queryRoleListByRoleType({ roleType }).then(res => {
          this.treeData = res || [];
          if (this.currentAccount?.roleType === roleType && this.treeData.length > 0) {
            this.$nextTick(() => {
              this.handleTreeMounted();
            });
          }
        });
      },
      // 菜单树初始化选中状态
      handleTreeMounted1() {
        if (this.currentAccount) {
          const keys = this.selectOperaIdList;
          console.log('handleTreeMounted1', keys);
          this.model.dataIdList = [...keys];
          this.$refs.YkcTree1.setCheckedKeys(keys, true);
        }
      },
      // 菜单树变化
      handleTreeCheckChange1() {
        const keys = this.$refs.YkcTree1.getCheckedKeys(true);
        console.log('keys', keys);
        this.model.dataIdList = keys;
      },
      handleTreeRadioChange(_, id) {
        this.model.dataIdList = [id];
      },
      // 菜单树初始化
      async getMenuTree1(status) {
        this.treeData1 = [];
        this.model.dataIdList = [];
        if (this.model.roleType === '2') {
          // 商户
          operatorData.userOperatorList({ name: '' }).then(res => {
            this.treeData1 = res || [];
            if (status && this.treeData1.length > 0) {
              this.$nextTick(() => {
                this.handleTreeMounted1();
              });
            }
          });
        } else if (this.model.roleType === '3') {
          // 电站
          treeUtils.operatorStations({}).then(res => {
            this.treeNodeKey = 'stationId';
            const listData = res || [];
            const kayMap = { operatorName: 'stationName', operatorId: 'stationId' };
            const setEmpty = (data, keyMap) => {
              const objs = Object.keys(data).reduce((newData, key) => {
                const newKey = keyMap[key] || key;
                newData[newKey] = data[key];
                return newData;
              }, {});
              return objs;
            };
            this.treeData1 = listData.map(item => {
              return setEmpty(item, kayMap);
            });

            if (status && this.treeData1.length > 0) {
              this.$nextTick(() => {
                this.handleTreeMounted1();
              });
            }
          });
        } else if (this.model.roleType === '4') {
          // 机构客户
          customerOrg.queryMainOrgList({}).then(res => {
            this.treeData1 = res || [];
            if (status && this.treeData1.length > 0) {
              this.$nextTick(() => {
                this.handleTreeMounted1();
              });
            }
          });
        }
      },
      // 保存
      beforeEnsure() {
        const reqParams = {
          ...this.model,
        };
        if (this.model.roleType === '4') {
          delete reqParams.alarmFlag;
        }
        if (this.currentAccount) {
          reqParams.id = this.currentAccount.id;
        }
        accountManage.save(reqParams).then(() => {
          this.stepsIndex = 0;
          this.model = initModel();
          this.$emit('finish');
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>

<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="tree-content">
      <el-tree
        ref="selectTree"
        :data="menuTree"
        v-if="!loadding"
        :accordion="true"
        :default-expand-all="true"
        :props="{
          value: 'id',
          label: 'label',
          children: 'subNodes',
        }">
        <span class="custom-tree-node" slot-scope="{ data }">
          <span class="label">{{ data.label }}</span>
          <span>
            <el-button
              v-if="data.level == 2"
              type="text"
              size="mini"
              style="margin-left: 30px"
              @click.stop="() => editRow(data)">
              编辑大区系数
            </el-button>
            <el-button
              v-if="data.level == 3"
              type="text"
              style="margin-left: 30px"
              size="mini"
              @click.stop="() => editRow(data)">
              编辑区域性质
            </el-button>
          </span>
        </span>
      </el-tree>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      title="编辑"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel"
      ensure-txt="保存">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit :data="curData" ref="addOrEdit"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import { Loading } from 'element-ui';
  import YkcDialog from '@/components/ykc-ui/dialog/index';
  import AddOrEdit from './AddOrEdit.vue';
  import { areaManageApi, treeUtils } from '@/service/apis';

  export default {
    name: 'MotorcadeMainAccountList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        curData: {},
        allDistrict: {},
        menuTree: [],
        showDrawer: false,
        addressData: [],
        loadingInstance: null,
        loadding: true,
        regionData: [
          { id: 1, name: '总部' },
          { id: 2, name: '华南大区' },
          { id: 3, name: '华中大区' },
          { id: 4, name: '华北大区' },
          { id: 5, name: '西南大区' },
          { id: 6, name: '华东大区' },
        ],
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },

          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit
                .submitForm()
                .then(() => {
                  this.showDrawer = false;

                  this.requestList();
                })
                .finally(() => {});
            },
          },
        ],

        searchParams: {
          id: '',
          ids: [],
          region: '',
        },
      };
    },
    mounted() {
      this.requestGetDistrict();
      this.requestList();
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcCascader',
            key: 'ids',
            label: '省市区',
            placeholder: '请选择省市区',
            configSet: {
              label: 'name',
              value: 'id',
              children: 'subNodes',
            },
            options: this.addressData,
            filterable: true,
            clearable: true,
            checkStrictly: true,
          },
          {
            comName: 'YkcDropdown',
            key: 'region',
            label: '所属大区',
            placeholder: '请选择所属大区',
            data: this.regionData,
          },
        ];
      },
    },
    methods: {
      /**
       * 获取区域数据
       */
      requestGetDistrict() {
        treeUtils
          .getDistrict({})
          .then(res => {
            this.addressData = res.districts;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取列表数据
       */
      requestList() {
        this.loadding = true;
        const el = document.querySelector('.tree-content');
        this.loadingInstance = Loading.service({ target: el });
        areaManageApi
          .getDistrict({ ...this.searchParams })
          .then(res => {
            this.menuTree = res;
            this.handleDistrictData(res);
            this.loadding = false;
            this.loadingInstance.close();
          })
          .catch(err => {
            this.loadding = false;
            this.loadingInstance.close();
          });
      },
      /**
       * 处理数据
       */
      handleDistrictData(data) {
        data.forEach(item => {
          this.allDistrict[item.id] = item;
          if (item.level === '1') {
            item.label = item.name;
          } else if (item.level === '2') {
            item.label =
              item.name +
              (item.regionName ? `-${item.regionName}` : '') +
              (item.coefficient ? `:${item.coefficient}` : '');
          } else {
            item.label = item.name + (item.natureName ? `-${item.natureName}` : '');
          }
          if (item.subNodes && item.subNodes.length) {
            this.handleDistrictData(item.subNodes);
          }
        });
      },

      /**
       * @desc 编辑
       *
       * */
      editRow(data) {
        this.curData = data;
        if (data.level === '2') {
          const proviceItem = this.allDistrict[data.parentId];
          this.curData.provinceName = proviceItem.name;

          this.curData.provinceId = proviceItem.id;
        }
        if (data.level === '3') {
          const cityItem = this.allDistrict[data.parentId];
          const proviceItem = this.allDistrict[cityItem.parentId];
          this.curData.provinceName = proviceItem.name;
          this.curData.provinceId = proviceItem.id;
          this.curData.cityName = cityItem.name;
          this.curData.cityId = cityItem.id;
        }

        this.showDrawer = true;
      },
      /**
       * 关闭
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        const len = this.searchParams.ids.length;
        if (len) {
          this.searchParams.id = this.searchParams.ids[len - 1];
        }

        Object.assign(this.searchParams, form);
        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .tree-content {
    min-height: 500px;
  }
  .label {
    display: inline-block;
    width: 300px;
  }
</style>
